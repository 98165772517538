@import '@bh/design-system/bundle';

ion-toolbar {
    border-radius: 0 0 0.5rem 0.5rem;
}

body {
    background: $medium-blue !important;

    ion-content {
        --ion-background-color: $medium-blue !important;

        bh-nav-header {
            &[slot='fixed'] {
                width: 100vw;
                background: #ffffff;

                + ion-content {
                    --padding-top: 5rem;
                }
            }

            ion-toolbar {
                padding-left: 0 !important;
            }
        }
    }

    &.white-header {
        background: white !important;

        ion-content {
            --ion-background-color: white !important;
        }
    }
    .confirmation-alert {
        min-width: 100% !important;
        color: $navy !important;
        top: calc(50% - 24px / 2) !important;
        font-family: sans-serif, Mulish !important;
    }

    .alert-button.sc-ion-alert-ios {
        height: 3.5rem;
    }

    .logout-alert {
        --min-width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0;
        padding-bottom: 0;

        .alert-head {
            padding-top: 0.25rem;
        }

        .alert-message {
            font-size: 0.875rem;
            padding-bottom: 0.5rem;
        }
    }

    ion-modal.auto-height {
        --height: auto;
        --border-radius: 0.5rem;
    }

    .tasksHeader {
        ion-toolbar:first-of-type {
            padding-top: 0;
        }
    }

    router-outlet {
        + * {
            background-color: white !important;
        }
    }

    .actionTile {
        ion-row {
            align-items: center;
        }
        bh-heading {
            --padding-top: 0 !important;
        }
        ion-checkbox {
            z-index: 0;
        }
    }

    .messageTitle p {
        font-weight: 700;
    }

    ion-modal.manualTP {
        --height: auto;
        --border-radius: 0.5rem 0.5rem 0 0;
        &::part(content){
            position: absolute;
            bottom: 0;
            padding-bottom: 1rem;
        }
    }

    .app-update-alert {
        backdrop-filter: blur(1.5rem);
        --backdrop-opacity: 10%;
        .alert-message {
            text-align: left;
            span {
                font-size: 0.8125rem;
                display: block;
                margin-top: 0.5rem;
            }
        }
    }
}

ion-content {
    --background: #fff;
}

ion-app {
    padding-top: env(safe-area-inset-top);
    padding-top: var(--ion-safe-area-top);
}

ion-back-button {
    --icon-font-size: 17.3px;
    padding: 0 1rem 0 1rem;
    margin: 0 -1rem 0 -1rem;
}

ion-backdrop {
    position: fixed;
    height: 100%;
    bottom: 0;
}

ion-action-sheet {
    --color: $charcoal !important;
    &.hasTitle {
        .action-sheet-title {
            border-bottom: 1px solid lightgray !important;
            padding-bottom: 12px !important;
            padding-top: 11px !important;
            font-size: 14px !important;

            .action-sheet-sub-title {
                font-weight: 600;
                font-size: 14px !important;
            }
        }
    }
    &.noTitle {
        .action-sheet-title {
            padding: 0 !important;
        }
    }

    .delete {
        color: $magenta !important;
        font-size: 1rem !important;
        &.ion-activated {
            color: $magenta !important;
        }
    }
    .cancel {
        color: $navy !important;
        font-size: 1rem !important;
        &.ion-activated {
            color: $navy !important;
        }
    }
}

ion-toast {
    --border-radius: 0.5rem;
    --border-width: 1px;
    --border-style: solid;
    background: rgba(0, 0, 0, 0.3);

    &::part(message) {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        max-width: 60vw;
        margin-left: -1.3rem;
    }

    &[color='danger'] {
        --border-color: #{$magenta};
        --ion-color-danger: #{$anthill};
        --ion-color-danger-contrast: #{$magenta};
    }

    &[color='success'] {
        --border-color: transparent;
        --ion-color-success: #{$enchanted-forest};
        --ion-color-success-contrast: #{$light-green};

        &::part(message) {
            color: $charcoal;
        }
    }

    &[color='light'] {
        --border-color: transparent;
        --padding-bottom: 1rem;
        &::part(button) {
            color: $navy;
        }
    }

    &.info {
        --ion-color-light: #{$sandcastle};
        background: none;
        color: $charcoal;

        &.success {
            &::part(button) {
                color: $light-green;
            }
        }

        &::part(message) {
            max-width: 100%;
        }

        &::part(button) {
            color: $charcoal;
        }
    }
}

.picker-wrapper.sc-ion-picker-ios {
    z-index: 12;
}

.mtz-download-btn {
    display: none !important;
}

bh-home,
bh-calendar,
bh-memories {
    &.ion-page-hidden {
        display: initial !important;
    }

    &.ion-page-invisible {
        opacity: 1 !important;
    }
}

.preload-img {
    height: 0;
    width: 0;
    opacity: 0;
    position: absolute;

    > ion-icon {
        height: 0;
        width: 0;
    }
}

ion-modal.datePickerModal{
    --height: auto;
    --border-radius: 0.5rem;
    &::part(content){
        position: absolute !important;
        bottom: -0.25rem !important;
    }
}

bh-document-detail-list {
    .main-slides > div > .swiper-slide {
        height: calc(100vh - 5rem) !important;
        overflow-y: auto;
        align-items: flex-start !important;
    }
}

bh-details-list{
    .mySwiper > div > .swiper-slide {
        overflow: hidden;
        display: block;
        padding: 0 0 2rem;
    }
}

ion-alert.disableBiometrics > .alert-wrapper > .alert-button-group > .alert-button:last-child {
    color: red;
}

.note-without-bullet {
    .activity-feed-view {
        ul>li {
            bh-text {
                font-size: unset !important;
            }
        }
    }
}

.multiple-dynamic-profile {
    .-large {
        width: 55px !important;
        height: 55px !important;
        line-height: 45px !important;
        border: 5px solid #ffffff !important;

        span {
            font-size: 18px !important;
        }

    }

    .-larger {
        width: 95px !important;
        height: 95px !important;
    }
}

ion-modal.memories-modal {
    padding-top: var(--ion-safe-area-top);

    ion-toolbar {
        padding-top: 0;
    }
}
